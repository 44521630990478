.loader_block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.67);
  display: flex;
  align-items: center;
  .spinningCircle {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0);
    border-top-color: 4px solid #f6f9fa;
    border-right-color: 4px solid #f8feff;
    -webkit-animation: single2 4s infinite linear;
    animation: single2 4s infinite linear;
    margin: 0 auto;
  }
}
@-webkit-keyframes single2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #7fc4d1;
    border-right-color: #7fc4d1;
  }
  50% {
    border-top-color: #ffffff;
    border-right-color: #ffffff;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #ffffff;
    border-right-color: #ffffff;
  }
}

@keyframes single2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #fff;
    border-right-color: #fff;
  }
  50% {
    border-top-color: #fff;
    border-right-color: #fff;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #fff;
    border-right-color: #fff;
  }
}
