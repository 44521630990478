// react transition
.transition-enter {
  opacity: 0.01;
}

.transition-enter-active {
  opacity: 1;
  //   transition: opacity 300ms ease-in;
}

.transition-exit {
  display: none;
  opacity: 0;
}

.transition-exit-active {
  opacity: 0;
  //   transition: opacity 300ms ease-in;
}
