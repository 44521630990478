.upload_img {
  position: absolute;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.79);
  width: 144px;
  right: 0px;
  border-radius: 22px 0 0px 0px;
  padding: 11px;
  font-size: 14px;
  color: #0a9ef8;
  img {
    width: 24px !important;
    height: auto !important;
    object-fit: contain;
    border-radius: 0px;
  }
  input {
    position: absolute;
    top: 0px;
    opacity: 0 !important;
    width: 100% !important;
    cursor: pointer;
  }
}

.errorMsg {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
}

.customName {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border: 1px solid #dcdcdc;
  border-radius: 15px;
  background: #f2faff;
  color: #005182;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea{
  color: #005182 !important;
}

.remove_icon {
  position: absolute !important;
  top: -10px !important;
  right: 10px !important;
}

.single_video_upload {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.spinner-border.text-secondary {
  position: absolute;
  margin: 110px;
}

.avail_cal {
  padding: 12px;
  border: 1px solid #cdcdcd;
  border-radius: 15px;
}

.add_time {
  position: absolute;
  /* left: 0; */
  right: -175px;
  color: #67dd9c;
  border: 1px solid #67dd9c;
  padding: 8px 16px;
  border-radius: 7px;
  cursor: pointer;
}

.trash {
  position: absolute;
  right: -215px;
}

.timezone_dropdown ul {
  transform: none !important;
  min-height: 400px !important;
  max-height: 400px !important;
  overflow: auto;
}

ul.dropdown-menu.pb-0.show {
  // width: max-content !important;
  top: 55px !important;
  left: -63px !important;
  right: 43px !important;
}

.login-facebook {
  position: relative;
  cursor: pointer;
}
.login-facebook span {
  // opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: none !important;
}
.my-facebook-button-class {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: none !important;
}

.sel_courses_list .list {
  height: 60vh;
  overflow-x: hidden;
}

.sel_courses_list .list .course_card_curve.m_course_img {
  background: none;
  background-size: cover;
  width: 100%;
  display: flex;
  height: 200px;
  align-items: flex-end;
  padding: 12px;
}

.sel_courses_list .list .course_card_curve:before,
.course_card_curve2:before,
.course_card_curve3:before,
.course_card_curve4:before,
.course_card_curve5:before,
.course_card_curve6:before,
.course_card_curve7:before,
.course_card_curve8:before,
.course_card_curve9:before,
.course_card_curve10:before,
.course_card_curve11:before,
.course_card_curve12:before {
  content: '';
  position: absolute;
  background-color: #00000029;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  z-index: -9;
  border-radius: 16px;
  background-image: none;
}

.sel_courses_list .list .course_card_curve .course_info h5 {
  font-size: 18px;
  margin-bottom: 3px;
}
.sel_courses_list .list .course_card_curve .course_info p,
.sel_courses_list .list .course_card_curve .course_info ul.list-inline {
  font-size: 14px;
}
.sel_courses_list .list .course_card_curve input.course_sel {
  position: absolute;
  top: 6px;
  right: 7px;
  height: 30px;
}

.sel_courses_list .list .course_card_curve .del_icon {
  position: absolute;
  width: 20px;
  /* top: -59px; */
  bottom: 69px;
  right: 25px;
}
.sel_courses_list .list .course_card_curve .del_icon svg {
  width: 34px;
}

.sel_courses_list .list .course_card_curve.m_course_img {
  background: none;
  background-size: cover;
  width: 100%;
  display: flex;
  height: 200px;
  align-items: flex-end;
  padding: 12px;
}

.sel_courses_list .list .course_card_curve:before,
.course_card_curve2:before,
.course_card_curve3:before,
.course_card_curve4:before,
.course_card_curve5:before,
.course_card_curve6:before,
.course_card_curve7:before,
.course_card_curve8:before,
.course_card_curve9:before,
.course_card_curve10:before,
.course_card_curve11:before,
.course_card_curve12:before {
  content: '';
  position: absolute;
  background-color: #00000029;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  z-index: -9;
  border-radius: 16px;
  background-image: none;
}

.sel_courses_list .list .course_card_curve .course_info h5 {
  font-size: 18px;
  margin-bottom: 3px;
}
.sel_courses_list .list .course_card_curve .course_info p,
.sel_courses_list .list .course_card_curve .course_info ul.list-inline {
  font-size: 14px;
}
.sel_courses_list .list .course_card_curve input.course_sel {
  position: absolute;
  top: 6px;
  right: 7px;
  height: 30px;
}

.sel_courses_list .list .course_card_curve .del_icon {
  position: absolute;
  width: 20px;
  /* top: -59px; */
  bottom: 69px;
  right: 25px;
}
.sel_courses_list .list .course_card_curve .del_icon svg {
  width: 34px;
}

// Direction change css start Arabic Language
body[dir='rtl'] {
  .form-select {
    background-position: left 0.75rem center;
  }
  .me-3 {
    margin-left: 1rem !important;
    margin-right: 0rem !important;
  }
  .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: 0;
  }
  .banner_bg:before {
    background-position: left;
  }
  // .banner_text ul {
  //   padding-left: 2rem;
  //   padding-right: 0rem;
  // }
  span.checkbox_tick {
    left: 0;
    right: auto;
  }
  .me-4 {
    margin-right: 0rem !important;
    margin-left: 1.5rem !important;
  }
  .ms-4 {
    margin-left: 0rem !important;
    margin-right: 1.5rem !important;
  }
  .ms-2 {
    margin-left: 0rem !important;
    margin-right: 0.5rem !important;
  }
  button svg {
    transform: rotateY(180deg);
  }
}

//   End of Direction change css start Arabic Language

// new additional

button.custom_fb {
  background: transparent !important;
  border: none !important;
} // facebook btn login css
#thankyou.thankyoumodal {
  background: #0000006b;
} // broker okay modal backdrop css

// Start of Broker sel date calender css

button.react-calendar__tile.react-calendar__month-view__days__day {
  border: none;
  padding: 8px 1px;
  margin-top: -1px;
  font-size: 18px;
  height: 56px;
  background: transparent;
  color: #001929;
}
.react-calendar__month-view__weekdays__weekday {
  margin-top: 30px;
  text-align: center;
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 18px;
  font-weight: 700;
  color: #005182;
}
.react-calendar__month-view abbr[title],
abbr[data-bs-original-title] {
  text-decoration: none !important;
}

button.react-calendar__navigation__label,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  background: transparent;
  border: 1px solid #fff !important;
  border-radius: 8px !important;
  padding: 7px 15px !important;
  color: #0a9ef8 !important;
  margin-right: 4px;
  font-weight: 600;
  font-size: 16px;
}

button.react-calendar__tile.react-calendar__year-view__months__month {
  background: #eaf7fe2b;
  padding: 12px;
  border: 1px solid #eaf7fe61;
}
.react-calendar__year-view {
  margin-top: 20px;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: #ff554b0d;
  color: #ff554b;
  font-weight: 600;
  border-radius: 48px;
}

.react-calendar.react-calendar--selectRange .react-calendar__navigation,
.react-calendar {
  text-align: center;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #00518238;
}
// End of Broker sel date calender css

.scroll_overflow {
  height: 60vh;
  overflow-x: hidden;
}
